<template>
  <base-card>
    <section id="banner">
      <v-row no-gutters>
        <v-img
          :height="'470px'"
          :src="require(`@/assets/images/` + banner)"
        >
          <v-theme-provider dark>
            <v-container class="fill-height">
              <v-row
                align="center"
                class="white--text mx-auto"
                justify="center"
              >
                <v-col
                  class="white--text text-center title"
                  cols="12"
                  tag="h1"
                >
                  <span
                    :class="[
                      $vuetify.breakpoint.smAndDown ? 'display-1' : 'display-3'
                    ]"
                    class="font-weight-black bannerTitle"
                  >
                    {{ title }}
                  </span>
                </v-col>
              </v-row>
            </v-container>
          </v-theme-provider>
        </v-img>
      </v-row>
    </section>
  </base-card>
</template>

<script>
  export default {
    name: 'BuySellBanner',
    props: {
      title: String,
      banner: String,
    },
  }
</script>
<style scoped>
  .title {
    padding-bottom: 170px;
  }

  .bannerTitle {
    background-color: #a2834c;
  }
</style>
